export default [
    {
        title: 'Carnegie Endowment for International Peace',
        locations: ['Washington DC, USA'],
        label: 'Global Locations',
        slug: 'global',
        id: 'global',
        path: '/',
        localeCodes: ['en'],
        blog: {
            title: 'emissary',
            path: '/emissary',
        },
        addressLines: ['1779 Massachusetts Avenue NW', 'Washington, DC 20036-2103'],
        phone: '202 483 7600',
        contactLines: ['Fax: 202 483 1840'],
        logos: {
            en: {
                logo: 'LogoGlobal',
            },
        },
        headlineTopicsRegions: [
            {
                title: 'Climate Change',
                path: '/topics/climate-change',
            },
            {
                title: 'Russia',
                path: '/regions/russia',
            },
        ],
        facebook: 'https://www.facebook.com/carnegieendowment',
        twitter: 'http://twitter.com/CarnegieEndow',
        linkedIn: 'http://www.linkedin.com/company/162903?trk=tyah',
        youtube: 'http://www.youtube.com/user/CarnegieEndowment/videos?view=0&flow=grid',
        instagram: 'https://www.instagram.com/carnegieendow/?hl=en',
        about: '/about',
        careers: '/employment-opportunities-at-the-carnegie-endowment-for-international-peace',
        contactEmail: 'mailto:info@ceip.org',
        privacy: '/privacy-policy',
        media: '/media-contacts-at-the-carnegie-endowment-for-international-peace?lang=en',
        government: '/government-resources?lang=en',
    },
    {
        title: 'Carnegie China',
        locations: ['Activities in Singapore and Beijing, China'],
        label: 'China',
        slug: 'china',
        id: 'china',
        path: '/china',
        localeCodes: ['en', 'zh'],
        logos: {
            en: {
                logo: 'LogoChinaEn',
            },
            zh: {
                logo: 'LogoChinaZh',
            },
        },
        facebook: 'https://www.facebook.com/CarnegieChina/',
        twitter: 'https://twitter.com/carnegiechina',
        linkedIn: 'https://www.linkedin.com/company/carnegiechina/',
        youtube: 'https://www.youtube.com/@CarnegieChina',
        about: '/china/about-carnegie-china',
        careers: '/china/employment-opportunities-at-carnegie-china',
        contactEmail: 'mailto:carnegiechina@ceip.org',
        privacy: '/privacy-policy',
        media: '/china/media-contacts-at-carnegie-china',
    },
    {
        title: 'Carnegie Europe',
        locations: ['Brussels, Belgium'],
        label: 'Europe',
        slug: 'europe',
        id: 'europe',
        path: '/europe',
        blog: {
            title: 'strategic europe',
            path: '/europe/strategic-europe',
        },
        addressLines: ['Rue du Congrès, 15', '1000 Brussels, Belgium'],
        localeCodes: ['en'],
        logos: {
            en: {
                logo: 'LogoEurope',
            },
        },
        facebook: 'https://www.facebook.com/CarnegieEurope?ref=s',
        twitter: 'http://twitter.com/Carnegie_Europe',
        linkedIn: 'https://www.linkedin.com/company/carnegie-europe/',
        youtube: 'https://www.youtube.com/@CarnegieEuropeBrussel/featured',
        about: '/europe/about-carnegie-europe',
        careers: '/europe/employment-opportunities-at-carnegie-europe',
        contactEmail: 'mailto:brussels@ceip.org',
        privacy: '/privacy-policy',
        media: '/europe/media-contacts-at-carnegie-europe',
    },
    {
        title: 'Carnegie India',
        locations: ['New Delhi, India'],
        label: 'India',
        slug: 'india',
        id: 'india',
        path: '/india',
        localeCodes: ['en', 'hi'],
        addressLines: ['Unit C-4, 5, 6, Edenpark', 'Shaheed Jeet Singh Marg', 'New Delhi – 110016, India'],
        addressLinesNonEnglish: ['यूनिट सी-4,5,6, इडेनपार्क,', 'शहीद जीत सिंह मार्ग,', 'नई दिल्ली- 110016, भारत '],
        phone: '011-40078687',
        logos: {
            en: {
                logo: 'LogoIndiaEn',
            },
            hi: {
                logo: 'LogoIndiaHi',
            },
        },
        facebook: 'https://www.facebook.com/CarnegieIndia?fref=ts',
        twitter: 'http://twitter.com/CarnegieIndia',
        linkedIn: 'https://www.linkedin.com/company/carnegie-india/',
        youtube: 'https://www.youtube.com/channel/UCihQxW838FMa_fWhGsK9F0Q',
        instagram: 'https://www.instagram.com/carnegieindia/',
        about: '/india/about-carnegie-india',
        careers: '/india/employment-opportunities-at-carnegie-india',
        contactEmail: 'mailto:carnegieindia@ceip.org',
        privacy: '/privacy-policy',
        media: '/india/media-contacts-at-carnegie-india?lang=en',
    },
    {
        title: 'Malcolm H. Kerr Carnegie Middle East Center',
        locations: ['Beirut, Lebanon'],
        label: 'Middle East',
        slug: 'middle-east',
        id: 'middleEast',
        path: '/middle-east',
        localeCodes: ['en', 'ar'],
        blog: {
            title: 'diwan',
            path: '/middle-east/diwan',
        },
        logos: {
            en: {
                logo: 'LogoMiddleEastEn',
            },
            ar: {
                logo: 'LogoMiddleEastAr',
            },
        },
        addressLines: [
            'Emir Bechir Street, Lazarieh Tower ',
            'Bldg. No. 2026 1210, 5th flr. ',
            'Downtown Beirut, P.O.Box 11-1061',
            'Riad El Solh, Lebanon',
        ],
        addressLinesNonEnglish: [
            'شارع الأمير بشير، برج العازارية ',
            'بناية 20261210، ط5 ',
            'وسط بيروت ص.ب 1061 -11 ',
            'رياض الصلح, لبنان',
        ],
        phone: '011-40078687',
        facebook: 'https://www.facebook.com/carnegiemec/?ref=ts',
        twitter: 'https://twitter.com/CarnegieMEC',
        linkedIn: 'https://www.linkedin.com/company/carnegie-middle-east-center?trk=top_nav_home',
        youtube: 'https://www.youtube.com/user/CarnegieMENA',
        about: '/middle-east/about-the-malcolm-h-kerr-carnegie-middle-east-center',
        careers: '/middle-east/employment-opportunities-at-the-malcolm-h-kerr-carnegie-middle-east-center',
        contactEmail: 'mailto:info@carnegie-mec.org',
        privacy: '/privacy-policy',
        media: '/middle-east/media-contacts-at-the-malcolm-h-kerr-carnegie-middle-east-center',
    },
    {
        title: 'Carnegie Russia Eurasia Center',
        locations: ['Berlin, Germany'],
        label: 'Russia Eurasia',
        slug: 'russia-eurasia',
        id: 'russiaEurasia',
        path: '/russia-eurasia',
        localeCodes: ['en', 'ru'],
        blog: {
            title: 'politika',
            path: '/russia-eurasia/politika',
        },
        logos: {
            en: {
                logo: 'LogoRussiaEurasiaEn',
            },
            de: {
                logo: 'LogoRussiaEurasiaEn',
            },
            ru: {
                logo: 'LogoRussiaEurasiaEn',
            },
        },
        headlineTopicsRegions: [
            {
                title: 'Ukraine',
                path: '/regions/ukraine',
            },
            {
                title: 'Central Asia',
                path: '/topics/central-asia',
            },
        ],
        footerLogo: {
            logo: 'LogoRussiaEurasiaRu',
        },
        facebook: 'https://www.facebook.com/CarnegieRussia/',
        facebookRu: 'https://www.facebook.com/CarnegieRussiaRU',
        twitter: 'https://twitter.com/CEIP_Politika',
        telegram: 'https://t.me/CarnegiePolitikaEn',
        twitterRu: 'https://twitter.com/CarnegieRu',
        telegramRu: 'https://t.me/carnegiepolitika',
        youtube: 'https://www.youtube.com/channel/UCyfLMb620ULg5Vv5RPnFoGQ',
        youtubeRu: 'https://www.youtube.com/@carnegiepolitika',
        about: '/russia-eurasia/about-carnegie-russia-eurasia-center',
        contactEmail: 'mailto:RussiaEurasiaCenter@ceip.org',
        privacy: '/privacy-policy',
    },
];
