<template>
    <SiteModal class="subscribe-modal" v-if="isModalOpen" @close-modal="closeModal()" variant="windowed">
        <template #content>
            <component :is="formComponent" :formContextData="toValue(formContextData)" />
        </template>
    </SiteModal>
</template>

<script setup>
import { storeToRefs } from 'pinia';

const props = defineProps({
    isModalOpen: {
        type: Boolean,
        default: false,
    },
    newsletterFormName: {
        type: String,
    },
    closeModal: {
        type: Function,
    },
});

const formComponent = props.newsletterFormName ? getNewsletterFormComponent(props.newsletterFormName) : null;
const { subscribeModalQueryVars } = storeToRefs(useSubscribeModalQueryVarsStore());

const formContextData = ref(null);

watch(
    () => props.isModalOpen,
    async () => {
        if (props.isModalOpen && !formContextData.value) {
            const { id, collection } = subscribeModalQueryVars.value;
            const { data } = await useSubscribeFormContext(id, collection);
            formContextData.value = data;
        }
    },
);
</script>

<style lang="scss" scoped>
.subscribe-modal {
}
</style>
