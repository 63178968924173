<template>
    <component :is="as" :class="CSSClasses" class="rich-text-elements">
        <div v-if="html" class="rich-text html" v-html="html" />
        <template v-else v-for="(currentElement, index) in elements?.root?.children" :key="index">
            <div class="block-item" v-if="currentElement.type === 'upload'">
                <ImageBlock v-if="currentElement.relationTo === 'images'" :image="currentElement.value" />
                <VideoBlock
                    v-else-if="currentElement.relationTo === 'videoPosters'"
                    v-bind="{ ...currentElement.value }"
                />
                <Button
                    v-else-if="currentElement.relationTo === 'files'"
                    :url="currentElement.value?.url"
                    variant="tertiary"
                    :newTab="true"
                    class="cta"
                    icon="download"
                    >{{ currentElement.value?.label }}</Button
                >
            </div>
            <div v-else-if="currentElement.type === 'block'" class="block-item" v-html="currentElement.fields?.html" />
            <div v-else v-html="renderedHtml([currentElement])" class="rich-text" />
        </template>
    </component>
</template>

<script setup>
const props = defineProps({
    elements: {
        type: Object,
        default: () => {},
    },
    as: {
        type: [String, Object],
        default: 'div',
    },
    bodyStyle: {
        type: String,
        default: 'serif-large',
        validator: (value) => {
            return [
                'serif-large',
                'serif-small',
                'sans-large',
                'body-sans-large',
                'caption',
                'pullquote',
                'blue',
                'body-display-micro-italic',
            ].includes(value);
        },
    },
    color: {
        type: String,
        default: '',
    },
    html: {
        type: String,
        default: '',
    },
    isCentered: {
        type: Boolean,
        default: false,
    },
    footnoteOffset: {
        type: Number,
    },
    size: {
        ...sizes,
    },
    theme: {
        ...themes,
    },
    ...streamBlock,
});

const CSSClasses = computed(() => {
    return [
        `body-style-${props.bodyStyle}`,
        `theme-${props.theme}`,
        `size-${props.size}`,
        props.color && `color-${props.color}`,
        props.pageContext && `page-context-${props.pageContext}`,
        props.isCentered && `centered`,
    ];
});

const renderedHtml = (element) => {
    return lexicalSerialize(element, props.footnoteOffset);
};
</script>

<style lang="scss" scoped>
.rich-text-elements {
    &.page-context-publication {
        opacity: 0.9;
    }

    &.page-context-collection-block {
        opacity: 0.6;
    }

    &.centered {
        :deep(.rich-text) {
            text-align: center;

            p {
                max-width: none;
            }
        }
    }
    &.theme-dark {
        color: color(blanc, default, 0.9);
    }
    .block-item {
        margin-bottom: vertical-space(2);
    }
    &.body-style-body-display-micro-italic {
        outline: none;
    }
}

.body-style-body-micro-pub-name {
    display: inline;
}

:deep(.rich-text),
:deep(.block-item) {
    --paragraph-bottom-margin: 1.8rem;
    --list-item-bottom-margin: 1rem;

    .body-style-body-micro-italic & {
        @include type-body-micro-italic;
    }

    .body-style-body-micro-italic.source-label & {
        width: max-content;
        &:before {
            content: ' \B7 ';
            margin-right: 0.2rem;
        }
        @include media-query(mobile) {
            width: 100%;
        }
    }

    .body-style-body-micro-pub-name & {
        @include type-body-micro;
        display: inline;
        margin-left: 0.3em;
    }

    .body-style-caption & {
        --paragraph-bottom-margin: 0.4rem;
    }

    .body-style-body-display-micro-italic & {
        @include type-body-display-micro-italic;
        outline: none;
    }

    h1,
    h2,
    h3 {
        margin-bottom: 1.4rem;
        margin-top: 4rem;

        @include media-query(phone) {
            margin-bottom: 0.8rem;
        }
    }

    &:first-child h1,
    &:first-child h2,
    &:first-child h3 {
        margin-top: 0;
    }

    h4,
    h5 {
        margin-bottom: 0.8rem;
        margin-top: 3rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        max-width: var(--body-text-max-width);
    }

    p,
    li {
        max-width: var(--body-text-max-width);

        .body-style-serif-large & {
            @include type-body-display-large;
        }

        .body-style-serif-small & {
            @include type-body-display-small;
            &:not(:last-child) {
                margin-bottom: 1.2rem;
            }
        }

        .body-style-sans-large & {
            @include type-body-x-large;
        }

        .body-style-body-sans-large & {
            @include type-body-large;
        }

        .body-style-caption & {
            @include type-body-display-micro;
        }

        .body-style-pullquote & {
            @include type-pullquote;
        }

        .color-blue & {
            color: palette(text-blue);
        }
    }

    p {
        margin-bottom: var(--paragraph-bottom-margin);
    }

    a {
        @include type-underline;
        text-decoration-color: palette(anchor-underline);
        overflow-wrap: break-word;
        &:hover {
            color: color(offnoir, hover);
            text-decoration-color: palette(anchor-underline-hover);
        }
        &:active {
            color: color(offnoir, active);
            text-decoration-color: palette(anchor-underline-active);
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;

        ::marker {
            line-height: 0;
        }
    }

    ol,
    ul {
        list-style-position: outside;
        margin-bottom: var(--paragraph-bottom-margin);
        margin-inline-start: 2em;
    }

    li {
        margin-bottom: var(--list-item-bottom-margin);
        li {
            list-style-type: circle;
            margin-inline-start: space(5);
            margin-top: var(--list-item-bottom-margin);
        }
    }
    sup {
        vertical-align: super;
        @include type-label-small;
        padding: 0 0.2rem;
    }
    .footnote {
        // color: color(teal);
        text-decoration-color: color(teal);
        text-decoration-thickness: 0.1rem;
        text-underline-offset: 0.2rem;
        @include type-label-small;
        vertical-align: super;
        padding: 0 0.2rem;
    }

    .size-100 & * {
        @include margin-lr(auto);
    }

    &:only-child {
        p:last-child {
            margin-bottom: 0;
        }
    }
    blockquote {
        @include type-pullquote;
        padding-inline-start: 3.2rem;
        border-inline-start: 0.4rem solid var(--bar-color, color(teal));
        margin-bottom: var(--paragraph-bottom-margin);
        color: #004070;
        margin-bottom: 1.5em;
        margin-top: 1.5em;
    }
}
//supporting html blocks in rich text
.size-100 .block-item {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--body-text-max-width);
}

// legacy video embeds on event pages
:deep(.videoEmbed) {
    margin-bottom: 4rem;
}
//- avatar
:deep(.avatar) {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    border: 1px solid #000000;
    border-radius: 50%;
    display: block;
    float: left;
    height: 6rem;
    width: 6rem;
    margin-right: 2rem !important;
    overflow: hidden;
}
//-table

:deep(table) {
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: var(--paragraph-bottom-margin);
    td {
        border-bottom: (rgba(var(--color-rgb-noir-default), 0.1) solid 1px);
        padding: 1.5rem;
    }
    tr.row-background-highlight {
        background-color: rgba(var(--color-rgb-noir-default), 0.1);
    }
    thead td.table-title {
        border-bottom: rgba(var(--color-rgb-tan-default), 1) solid 1px;
        color: rgba(var(--color-rgb-tan-default), 1);
        font-size: 1.3em;
        padding-bottom: 0.5em;
        text-align: left;
        @include media-query(mobile) {
            font-size: 0.8em;
        }
    }
    tbody td,
    tfoot td {
        font-weight: 350;
        font-size: 0.9em;
        padding: 0.7em;
        p {
            font-size: 0.9em !important;
        }
        li {
            font-size: 0.9em !important;
        }
        @include media-query(mobile) {
            font-size: 0.8em;
            padding: 0.5em;
            p {
                font-size: 0.8em !important;
            }
        }
    }
}
//-supporting legacy text box styles
:deep(.text-box) {
    background-color: #e6e7e8;
    display: inline-block;
    margin-top: 0;
    margin-bottom: var(--paragraph-bottom-margin);
    padding: 3rem;
    width: 100%;

    h3,
    h4,
    h5 {
        margin-top: 0;
    }

    h5 {
        color: #000000;
        text-transform: uppercase;
    }

    strong {
        color: #929497;
        font-size: 1.3em;
        padding: 0 3rem 0 0;
    }

    .section {
        margin-top: 0;
    }
}
</style>
