import { storeToRefs } from 'pinia';
import centers from '@/constants/centers.js';

export default function () {
    const currentRoute = useRoute();

    const getCenterSlugFromRoute = (route) => {
        return route.params.center || route.query.center || 'global';
    };
    const slug = computed(() => {
        return getCenterSlugFromRoute(currentRoute);
    });
    const currentCenter = computed(() => {
        return (
            centers.find((center) => center.slug === slug.value) || centers.find((center) => center.slug === 'global')
        );
    });

    const isGlobalCenter = computed(() => {
        return currentCenter.value.slug === 'global';
    });

    const getCenterPageUrl = (slug) => {
        if (slug) {
            return currentCenter.value.slug === 'global' ? `/${slug}` : `${currentCenter.value.path}/${slug}`;
        } else {
            return currentCenter.value.slug === 'global' ? `/` : currentCenter.value.path;
        }
    };

    // Combines tags, topics, and regions by center for content detail tag cluster
    const getCenterTagsBySyndication = (syndications, tags) => {
        const center = currentCenter.value.id;

        const { currentCenterTopics, currentCenterRegions } = storeToRefs(useCentersConfigStore());

        //checks if tags on the global syndication exist in the center config and filters them out if they dont.
        const getFilteredTags = (globalTags, centerTags, centerConfigTags) => {
            const filteredGlobalTags = globalTags?.filter((tag) => {
                const findTag = centerConfigTags.findIndex((centerTag) => {
                    return centerTag.title === tag.title;
                });
                return findTag >= 0;
            });

            return (
                [...(filteredGlobalTags || []), ...(centerTags || [])]
                    //removes duplicate values between the global and center tags
                    .filter((v, i, a) => a.findIndex((v2) => v2.title === v.title) === i)
            );
        };

        const topicTags =
            center === 'global'
                ? syndications?.centers[`${center}`]?.topics || []
                : getFilteredTags(
                      syndications?.centers?.global.topics,
                      syndications?.centers[`${center}`]?.topics,
                      currentCenterTopics.value.docs,
                  );

        const regionTags =
            center === 'global'
                ? syndications?.centers[`${center}`]?.regions || []
                : getFilteredTags(
                      syndications?.centers?.global.regions,
                      syndications?.centers[`${center}`]?.regions,
                      currentCenterRegions.value.docs,
                  );

        const cleanedTags = tags?.filter((item) => {
            return item.path !== '/tags/original-content';
        });
        return [...topicTags, ...regionTags, ...cleanedTags];
    };

    return {
        centers,
        currentCenter,
        getCenterSlugFromRoute,
        getCenterPageUrl,
        getCenterTagsBySyndication,
        isGlobalCenter,
    };
}
