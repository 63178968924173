//This copy-and-pasted from somewhere in lexical here: https://github.com/facebook/lexical/blob/c2ceee223f46543d12c574e62155e619f9a18a5d/packages/lexical/src/LexicalConstants.ts
import escapeHTML from 'escape-html';

// DOM
export const DOM_ELEMENT_TYPE = 1;
export const DOM_TEXT_TYPE = 3;

// Reconciling
export const NO_DIRTY_NODES = 0;
export const HAS_DIRTY_NODES = 1;
export const FULL_RECONCILE = 2;

// Text node modes
export const IS_NORMAL = 0;
export const IS_TOKEN = 1;
export const IS_SEGMENTED = 2;
// IS_INERT = 3

// Text node formatting
export const IS_BOLD = 1;
export const IS_ITALIC = 1 << 1;
export const IS_STRIKETHROUGH = 1 << 2;
export const IS_UNDERLINE = 1 << 3;
export const IS_CODE = 1 << 4;
export const IS_SUBSCRIPT = 1 << 5;
export const IS_SUPERSCRIPT = 1 << 6;
export const IS_HIGHLIGHT = 1 << 7;

export const IS_ALL_FORMATTING =
    IS_BOLD | IS_ITALIC | IS_STRIKETHROUGH | IS_UNDERLINE | IS_CODE | IS_SUBSCRIPT | IS_SUPERSCRIPT | IS_HIGHLIGHT;

export const IS_DIRECTIONLESS = 1;
export const IS_UNMERGEABLE = 1 << 1;

// Element node formatting
export const IS_ALIGN_LEFT = 1;
export const IS_ALIGN_CENTER = 2;
export const IS_ALIGN_RIGHT = 3;
export const IS_ALIGN_JUSTIFY = 4;
export const IS_ALIGN_START = 5;
export const IS_ALIGN_END = 6;

export const TEXT_TYPE_TO_FORMAT = {
    bold: IS_BOLD,
    code: IS_CODE,
    italic: IS_ITALIC,
    strikethrough: IS_STRIKETHROUGH,
    subscript: IS_SUBSCRIPT,
    superscript: IS_SUPERSCRIPT,
    underline: IS_UNDERLINE,
};

function getLinkForPage(doc) {
    return doc?.value?.path || doc?.value?.url;
}

export default function lexicalSerialize(children) {
    if (!children) return '';
    const route = useRoute();

    return children
        .map((node) => {
            if (node.type === 'text') {
                //isText
                let text = `${escapeHTML(node.text)}`;

                if (node.format & IS_BOLD) {
                    text = `<strong>${text}</strong>`;
                }
                if (node.format & IS_ITALIC) {
                    text = `<em>${text}</em>`;
                }

                if (node.format & IS_STRIKETHROUGH) {
                    text = `<span class="line-through">${text}</span>`;
                }

                if (node.format & IS_UNDERLINE) {
                    text = `<span class="underline">${text}</span>`;
                }

                if (node.format & IS_CODE) {
                    text = `<code>${text}</code>`;
                }

                if (node.format & IS_SUBSCRIPT) {
                    text = `<sub>${text}</sub>`;
                }

                if (node.format & IS_SUPERSCRIPT) {
                    text = `<sup>${text}</sup>`;
                }

                return `${text}`;
            }

            if (!node) {
                return null;
            }

            const serializedChildren = node.children ? lexicalSerialize(node.children) : null;

            switch (node.type) {
                case 'linebreak':
                    return `<br>`;
                case 'link':
                    // eslint-disable-next-line no-case-declarations
                    const attributes = node?.fields;

                    if (attributes?.linkType === 'custom') {
                        return `<a href="${attributes.url}"${attributes?.newTab ? ' target=_"blank"' : ''} rel="${
                            attributes?.rel ?? ''
                        }${attributes?.sponsored ? ' sponsored' : ''}${
                            attributes?.nofollow ? ' nofollow' : ''
                        }">${serializedChildren}</a>`;
                    } else {
                        return `<a href="${getLinkForPage(attributes?.doc)}" ${
                            attributes?.newTab ? ' target=_"blank"' : ''
                        } rel="${attributes?.rel ?? ''}${attributes?.sponsored ? ' sponsored' : ''}${
                            attributes?.nofollow ? ' nofollow' : ''
                        }">${serializedChildren}</a>`; // FIXME: Check doc link handling
                    }
                case 'list': // FIXME: handle properly, especially nested lists
                    if (node.listType === 'bullet') {
                        return `
                    	<ul class="list-disc mb-4 pl-8">
                    	  ${serializedChildren}
                        </ul>`;
                    } else {
                        return `
                    	<ol class="list-disc mb-4 pl-8">
                    	  ${serializedChildren}
                    	</ol>`;
                    }
                case 'listitem':
                    return `
						<li>
						  ${serializedChildren}
						</li>`;
                case 'heading':
                    return `
                        <${node.tag}>
                            ${serializedChildren}
                        </${node.tag}>`;
                case 'quote':
                    return `
                        <blockquote>
                            ${serializedChildren}
                        </blockquote>`;
                case 'footnote': {
                    return `<sup class="footnote">${node.footnoteId}</sup>`;
                    // FIXME: chapters restart notes at 1 so chapter 7 note 1 links to chapter 1 note 1
                    // return `<sup class="footnote"><a href="#footnote-${node.footnoteId}" >${node.footnoteId}</a></sup>`;
                }

                case 'block':
                    // FIXME: this currently assumes HTMLBlock, this will break if we include more block types
                    return `${node.fields?.data?.html}`;

                default: //Probably just a normal paragraph
                    return serializedChildren ? `<p>${serializedChildren}</p>` : '<br />';
            }
        })
        .filter((node) => node !== null)
        .join('');
}
