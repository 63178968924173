<template>
    <div>
        <div :class="CSSClasses" v-if="mode === 'full'">
            <div class="wrapper">
                <div class="subscribe-content-wrap">
                    <div class="subscribe-content">
                        <Typography as="div" :variant="titleVariant" class="title">
                            <em class="title-accent">{{ $t('Get more news and insights from') }}</em
                            ><br />
                            <span>{{ $t(title) }}</span>
                        </Typography>

                        <RichTextElements
                            v-if="size !== '25' && size !== '100'"
                            :elements="dek"
                            :body-style="richTextStyle"
                            color=""
                            class="dek"
                        />
                        <div>
                            <Button
                                variant="primary"
                                class="cta"
                                :colorOverride="highlightColor"
                                @click="handleSubscribeCtaClicked"
                                >{{ $t('subscribe') }}</Button
                            >
                        </div>
                    </div>
                </div>
                <div v-if="hero?.image && (size === '100' || size === 'full')" class="image-wrap">
                    <Image v-bind="hero.image" class="image" />
                </div>
            </div>
        </div>
        <div v-else>
            <Button v-if="buttonLabel" variant="secondary" class="cta" @click="handleSubscribeCtaClicked">{{
                buttonLabel
            }}</Button>
        </div>
        <ClientOnly>
            <Teleport to="#teleported-content">
                <SubscribeModal
                    :isModalOpen="isSubscribeModalOpen"
                    :closeModal="closeSubscribeModal"
                    :newsletterFormName="newsletterFormName"
                />
            </Teleport>
        </ClientOnly>
    </div>
</template>

<script setup>
import { inject } from 'vue';

const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    dek: {
        type: Object,
    },
    hero: {
        type: Object,
    },
    size: {
        ...sizes,
    },
    theme: {
        type: String,
    },
    newsletterFormName: {
        type: String,
    },
    mode: {
        type: String,
        default: 'full',
        validator: (value) => {
            return ['full', 'button'].includes(value);
        },
    },
    buttonLabel: {
        type: String,
        default: '',
    },
});
const isSubscribeModalOpen = ref(false);

const closeSubscribeModal = () => {
    isSubscribeModalOpen.value = false;
};

const handleSubscribeCtaClicked = () => {
    isSubscribeModalOpen.value = true;
};

const highlightColor = inject('colorOverride', null);
const direction = inject('direction');

const CSSClasses = computed(() => [
    'subscribe-block',
    `size-${props.size}`,
    `theme-${inject('tierTheme', 'light') || props.theme}`,
    `direction-${direction.value}`,
    highlightColor ? `colorOverride` : null,
]);

const titleVariant = computed(() => {
    if (props.size === '25') {
        return 'body-display-large';
    }
    if (props.size === '33' || props.size === '50') {
        return 'h4-display';
    }
    if (props.size === '100') {
        return 'h3-display';
    }
    if (props.size === 'full') {
        return 'h2-display';
    }
});
const richTextStyle = computed(() => {
    if (props.size === 'full') {
        return 'serif-large';
    }
    return 'serif-small';
});
</script>

<style lang="scss" scoped>
.subscribe-block {
    outline: 1px solid palette(border-light);
    border-radius: 0.4rem;
    background-color: color(blanc);

    .image {
        height: 100%;
        background: v-bind(highlightColor);
    }

    .title {
        @include padding-tb(2.4rem);
        position: relative;
        max-width: var(--body-text-max-width);

        &:before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 4.8rem;
            height: 0.5rem;
            background: color(teal);
        }
    }

    .title-accent {
        color: color(noir, default, 0.6);
    }

    .dek {
        margin-bottom: 1em;
        color: color(noir, default, 0.7);
    }

    .subscribe-form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.6rem;
        border: 1px solid color(noir, default, 0.8);
        border-radius: 0.2rem;

        input {
            width: 100%;
        }
    }

    &.colorOverride {
        .title {
            &:before {
                background: v-bind(highlightColor);
            }
        }
    }

    &.size-25 {
        padding: 3.6rem 2.4rem;
    }

    &.size-33 {
        padding: 3.6rem;
    }

    &.size-50 {
        padding: 4.8rem;
    }

    &.size-100 {
        .subscribe-content {
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .subscribe-content-wrap {
            flex-basis: 64%;
            padding: 4.8rem;
        }

        .image-wrap {
            flex-basis: 36%;
        }

        .wrapper {
            display: flex;
            justify-content: space-between;
        }
    }

    &.size-full {
        outline: none;

        .wrapper {
            @include media-query(tablet-mw) {
                display: flex;
            }
        }

        .subscribe-content {
            text-align: center;

            @include media-query(tablet-mw) {
                text-align: left;
            }
        }

        .title {
            &:before {
                left: 50%;
                transform: translateX(-50%);

                @include media-query(tablet-mw) {
                    left: 0;
                    transform: none;
                }
            }
        }

        .image-wrap {
            flex-basis: 50%;
            display: none;

            @include media-query(tablet-mw) {
                display: block;
            }
        }
        .image {
            aspect-ratio: 1/1;
            max-height: var(--image-max-height);
        }

        .dek {
            display: none;

            @include media-query(phone-mw) {
                display: block;
            }
        }

        .subscribe-content-wrap {
            @include content-section;
            display: flex;
            align-items: center;
            padding-top: 8rem;
            padding-bottom: 8rem;

            @include media-query(tablet-mw) {
                padding-inline-start: var(--page-margin);
                padding-inline-end: 17.6rem;
                flex-basis: 50%;
                justify-content: flex-end;
            }
        }
    }

    &.theme-dark {
        &.size-full {
            background-color: color(blue, dark);

            .title span {
                color: color(blanc);
            }

            .title-accent {
                color: color(blanc, default, 0.6);
            }

            .dek {
                color: color(blanc, default, 0.7);
            }
        }
    }

    &.direction-rtl {
        direction: rtl;

        .subscribe-content {
            text-align: right;
        }

        .title {
            &:before {
                right: 0;
                left: unset;
            }
        }

        &.size-full {
            .title {
                &:before {
                    right: 50%;
                    transform: translateX(50%);

                    @include media-query(tablet-mw) {
                        right: 0;
                        transform: none;
                    }
                }
            }

            .subscribe-content {
                text-align: center;

                @include media-query(tablet-mw) {
                    text-align: right;
                }
            }
        }
    }
}
@include media-query(phone) {
    .subscribe-block-parent {
        margin: 3rem 0;
    }
}
</style>
