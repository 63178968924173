import { default as _91_46_46_46slug_93zaiicGtgCIMeta } from "/home/node/app/pages/[[center]]/[...slug].vue?macro=true";
import { default as blogsC4WhC0so6SMeta } from "/home/node/app/pages/[[center]]/blogs.vue?macro=true";
import { default as eventslWT4rZ8aawMeta } from "/home/node/app/pages/[[center]]/events.vue?macro=true";
import { default as expertsvRTA30Prp4Meta } from "/home/node/app/pages/[[center]]/experts.vue?macro=true";
import { default as _91slug_93sZfVLVkpgfMeta } from "/home/node/app/pages/[[center]]/people/[slug].vue?macro=true";
import { default as podcastsnp6sFJbyF1Meta } from "/home/node/app/pages/[[center]]/podcasts.vue?macro=true";
import { default as programs_45and_45projects4rghmlQ2G6Meta } from "/home/node/app/pages/[[center]]/programs-and-projects.vue?macro=true";
import { default as _91slug_93lvXPGbwBurMeta } from "/home/node/app/pages/[[center]]/regions/[slug].vue?macro=true";
import { default as researchKGsJPCTbAtMeta } from "/home/node/app/pages/[[center]]/research.vue?macro=true";
import { default as searchDKXkHfoZkfMeta } from "/home/node/app/pages/[[center]]/search.vue?macro=true";
import { default as staff62Ar3csNNBMeta } from "/home/node/app/pages/[[center]]/staff.vue?macro=true";
import { default as _91slug_93vyyDm5Q6HUMeta } from "/home/node/app/pages/[[center]]/topics/[slug].vue?macro=true";
import { default as _91slug_931mL2zdMoLcMeta } from "/home/node/app/pages/collections/[slug].vue?macro=true";
import { default as _91slug_93ZWU8bgLnPcMeta } from "/home/node/app/pages/events/[slug].vue?macro=true";
import { default as _91slug_93DM5Q9uAL4DMeta } from "/home/node/app/pages/events/[year]/[month]/[slug].vue?macro=true";
import { default as _91slug_93tp0IvrpDDDMeta } from "/home/node/app/pages/features/[slug].vue?macro=true";
import { default as africa_45digital_45regulationssdzkDSI3KAMeta } from "/home/node/app/pages/features/africa-digital-regulations.vue?macro=true";
import { default as african_45climate_45research_45organizationsqDvYfl3pZhMeta } from "/home/node/app/pages/features/african-climate-research-organizations.vue?macro=true";
import { default as ai_45global_45surveillance_45technologyz1IA8MRIxiMeta } from "/home/node/app/pages/features/ai-global-surveillance-technology.vue?macro=true";
import { default as back_45channeldpbBhO2dGPMeta } from "/home/node/app/pages/features/back-channel.vue?macro=true";
import { default as climate_45protest_45trackerwXDsNJ93WLMeta } from "/home/node/app/pages/features/climate-protest-tracker.vue?macro=true";
import { default as commercial_45spywareDkoKkFrDWzMeta } from "/home/node/app/pages/features/commercial-spyware.vue?macro=true";
import { default as fincyber_45timelineSb9w4QaECnMeta } from "/home/node/app/pages/features/fincyber-timeline.vue?macro=true";
import { default as global_45protest_45trackermj1cHREIksMeta } from "/home/node/app/pages/features/global-protest-tracker.vue?macro=true";
import { default as global_45russiaB6EfNb4lLSMeta } from "/home/node/app/pages/features/global-russia.vue?macro=true";
import { default as india_45elects_452024Ozzz8ExNFBMeta } from "/home/node/app/pages/features/india-elects-2024.vue?macro=true";
import { default as palestinian_45prisoner_45paymentswuDWiUOfkMMeta } from "/home/node/app/pages/features/palestinian-prisoner-payments.vue?macro=true";
import { default as radical_45right_45europe_45foreign_45policyeM5Nk6tlQKMeta } from "/home/node/app/pages/features/radical-right-europe-foreign-policy.vue?macro=true";
import { default as timeline_45of_45us_45policy_45toward_45palestineguIcctJFxkMeta } from "/home/node/app/pages/features/timeline-of-us-policy-toward-palestine.vue?macro=true";
import { default as tunisian_45elections_4520198PmnLZX2bzMeta } from "/home/node/app/pages/features/tunisian-elections-2019.vue?macro=true";
import { default as _91slug_931nNIsD6zz2Meta } from "/home/node/app/pages/in-the-media/[year]/[month]/[slug].vue?macro=true";
import { default as _91slug_93OSiEei0EJYMeta } from "/home/node/app/pages/podcasts/[episode]/[slug].vue?macro=true";
import { default as _91slug_93gI7juTp1zQMeta } from "/home/node/app/pages/podcasts/[slug].vue?macro=true";
import { default as _91slug_93zFDdQafAfdMeta } from "/home/node/app/pages/posts/[year]/[month]/[slug].vue?macro=true";
import { default as _91slug_931kY3w09P7gMeta } from "/home/node/app/pages/press/[year]/[month]/[slug].vue?macro=true";
import { default as _91slug_93tgiKT7hpi6Meta } from "/home/node/app/pages/programs/[slug].vue?macro=true";
import { default as _91slug_93MOoE1Z19PNMeta } from "/home/node/app/pages/projects/[slug].vue?macro=true";
import { default as _91slug_93McLKqyv9b1Meta } from "/home/node/app/pages/research/[year]/[month]/[slug].vue?macro=true";
import { default as _91slug_93GVbLY3WciSMeta } from "/home/node/app/pages/videos/[year]/[month]/[slug].vue?macro=true";
export default [
  {
    name: "center-slug",
    path: "/:center?/:slug(.*)*",
    component: () => import("/home/node/app/pages/[[center]]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "center-blogs",
    path: "/:center?/blogs",
    component: () => import("/home/node/app/pages/[[center]]/blogs.vue").then(m => m.default || m)
  },
  {
    name: "center-events",
    path: "/:center?/events",
    component: () => import("/home/node/app/pages/[[center]]/events.vue").then(m => m.default || m)
  },
  {
    name: "center-experts",
    path: "/:center?/experts",
    component: () => import("/home/node/app/pages/[[center]]/experts.vue").then(m => m.default || m)
  },
  {
    name: "center-people-slug",
    path: "/:center?/people/:slug()",
    component: () => import("/home/node/app/pages/[[center]]/people/[slug].vue").then(m => m.default || m)
  },
  {
    name: "center-podcasts",
    path: "/:center?/podcasts",
    component: () => import("/home/node/app/pages/[[center]]/podcasts.vue").then(m => m.default || m)
  },
  {
    name: "center-programs-and-projects",
    path: "/:center?/programs-and-projects",
    component: () => import("/home/node/app/pages/[[center]]/programs-and-projects.vue").then(m => m.default || m)
  },
  {
    name: "center-regions-slug",
    path: "/:center?/regions/:slug()",
    component: () => import("/home/node/app/pages/[[center]]/regions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "center-research",
    path: "/:center?/research",
    component: () => import("/home/node/app/pages/[[center]]/research.vue").then(m => m.default || m)
  },
  {
    name: "center-search",
    path: "/:center?/search",
    component: () => import("/home/node/app/pages/[[center]]/search.vue").then(m => m.default || m)
  },
  {
    name: "center-staff",
    path: "/:center?/staff",
    component: () => import("/home/node/app/pages/[[center]]/staff.vue").then(m => m.default || m)
  },
  {
    name: "center-topics-slug",
    path: "/:center?/topics/:slug()",
    component: () => import("/home/node/app/pages/[[center]]/topics/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/home/node/app/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/home/node/app/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events-year-month-slug",
    path: "/events/:year()/:month()/:slug()",
    component: () => import("/home/node/app/pages/events/[year]/[month]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "features-slug",
    path: "/features/:slug()",
    component: () => import("/home/node/app/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: "features-africa-digital-regulations",
    path: "/features/africa-digital-regulations",
    component: () => import("/home/node/app/pages/features/africa-digital-regulations.vue").then(m => m.default || m)
  },
  {
    name: "features-african-climate-research-organizations",
    path: "/features/african-climate-research-organizations",
    component: () => import("/home/node/app/pages/features/african-climate-research-organizations.vue").then(m => m.default || m)
  },
  {
    name: "features-ai-global-surveillance-technology",
    path: "/features/ai-global-surveillance-technology",
    component: () => import("/home/node/app/pages/features/ai-global-surveillance-technology.vue").then(m => m.default || m)
  },
  {
    name: "features-back-channel",
    path: "/features/back-channel",
    component: () => import("/home/node/app/pages/features/back-channel.vue").then(m => m.default || m)
  },
  {
    name: "features-climate-protest-tracker",
    path: "/features/climate-protest-tracker",
    component: () => import("/home/node/app/pages/features/climate-protest-tracker.vue").then(m => m.default || m)
  },
  {
    name: "features-commercial-spyware",
    path: "/features/commercial-spyware",
    component: () => import("/home/node/app/pages/features/commercial-spyware.vue").then(m => m.default || m)
  },
  {
    name: "features-fincyber-timeline",
    path: "/features/fincyber-timeline",
    component: () => import("/home/node/app/pages/features/fincyber-timeline.vue").then(m => m.default || m)
  },
  {
    name: "features-global-protest-tracker",
    path: "/features/global-protest-tracker",
    component: () => import("/home/node/app/pages/features/global-protest-tracker.vue").then(m => m.default || m)
  },
  {
    name: "features-global-russia",
    path: "/features/global-russia",
    component: () => import("/home/node/app/pages/features/global-russia.vue").then(m => m.default || m)
  },
  {
    name: "features-india-elects-2024",
    path: "/features/india-elects-2024",
    component: () => import("/home/node/app/pages/features/india-elects-2024.vue").then(m => m.default || m)
  },
  {
    name: "features-palestinian-prisoner-payments",
    path: "/features/palestinian-prisoner-payments",
    component: () => import("/home/node/app/pages/features/palestinian-prisoner-payments.vue").then(m => m.default || m)
  },
  {
    name: "features-radical-right-europe-foreign-policy",
    path: "/features/radical-right-europe-foreign-policy",
    component: () => import("/home/node/app/pages/features/radical-right-europe-foreign-policy.vue").then(m => m.default || m)
  },
  {
    name: "features-timeline-of-us-policy-toward-palestine",
    path: "/features/timeline-of-us-policy-toward-palestine",
    component: () => import("/home/node/app/pages/features/timeline-of-us-policy-toward-palestine.vue").then(m => m.default || m)
  },
  {
    name: "features-tunisian-elections-2019",
    path: "/features/tunisian-elections-2019",
    component: () => import("/home/node/app/pages/features/tunisian-elections-2019.vue").then(m => m.default || m)
  },
  {
    name: "in-the-media-year-month-slug",
    path: "/in-the-media/:year()/:month()/:slug()",
    component: () => import("/home/node/app/pages/in-the-media/[year]/[month]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "podcasts-episode-slug",
    path: "/podcasts/:episode()/:slug()",
    component: () => import("/home/node/app/pages/podcasts/[episode]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "podcasts-slug",
    path: "/podcasts/:slug()",
    component: () => import("/home/node/app/pages/podcasts/[slug].vue").then(m => m.default || m)
  },
  {
    name: "posts-year-month-slug",
    path: "/posts/:year()/:month()/:slug()",
    component: () => import("/home/node/app/pages/posts/[year]/[month]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "press-year-month-slug",
    path: "/press/:year()/:month()/:slug()",
    component: () => import("/home/node/app/pages/press/[year]/[month]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "programs-slug",
    path: "/programs/:slug()",
    component: () => import("/home/node/app/pages/programs/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projects-slug",
    path: "/projects/:slug()",
    component: () => import("/home/node/app/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: "research-year-month-slug",
    path: "/research/:year()/:month()/:slug()",
    component: () => import("/home/node/app/pages/research/[year]/[month]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "videos-year-month-slug",
    path: "/videos/:year()/:month()/:slug()",
    component: () => import("/home/node/app/pages/videos/[year]/[month]/[slug].vue").then(m => m.default || m)
  }
]