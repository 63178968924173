<template>
    <footer :dir="siteDirection" class="site-footer" :class="CSSClasses">
        <div class="site-footer-wrapper">
            <div class="branding">
                <Anchor :url="getCenterPageUrl('')" class="logo-wrap">
                    <div class="logo-lang">
                        <CenterLogo
                            v-if="currentCenter.footerLogo && locale == 'ru'"
                            :logoComponent="currentCenter.footerLogo.logo"
                            :isStacked="true"
                        >
                        </CenterLogo>
                        <CenterLogo v-else :logoComponent="currentCenter.logos[locale]?.logo" :isStacked="true">
                        </CenterLogo>
                    </div>
                </Anchor>
                <div class="contact">
                    <Typography variant="body">
                        <address v-if="currentCenter.addressLines && locale == 'en'" class="address">
                            <span v-for="line in currentCenter.addressLines" :key="line" class="contact-detail">
                                {{ line }}
                            </span>
                        </address>
                        <address v-else-if="currentCenter.addressLinesNonEnglish && locale !== 'en'" class="address">
                            <span
                                v-for="line in currentCenter.addressLinesNonEnglish"
                                :key="line"
                                class="contact-detail"
                            >
                                {{ line }}
                            </span>
                        </address>
                        <a :href="`tel:${currentCenter.phone}`" v-if="currentCenter.phone" class="contact-detail">
                            {{ $t('Phone') }}: {{ currentCenter.phone }}
                        </a>
                        <div v-for="line in currentCenter.contactLines" :key="line" class="contact-detail">
                            {{ line }}
                        </div>
                    </Typography>
                </div>
                <div class="social-media">
                    <IconLink
                        v-if="currentCenter.twitterRu && locale == 'ru'"
                        :destination="`${currentCenter.twitterRu}`"
                        icon="twitter"
                        theme="dark"
                    />
                    <IconLink
                        v-else-if="currentCenter.twitter"
                        :destination="`${currentCenter.twitter}`"
                        icon="twitter"
                        theme="dark"
                    />
                    <IconLink
                        v-if="currentCenter.facebookRu && locale == 'ru'"
                        :destination="`${currentCenter.facebookRu}`"
                        icon="facebook"
                        theme="dark"
                    />
                    <IconLink
                        v-else-if="currentCenter.facebook"
                        :destination="`${currentCenter.facebook}`"
                        icon="facebook"
                        theme="dark"
                    />
                    <IconLink
                        v-if="currentCenter.linkedIn"
                        :destination="`${currentCenter.linkedIn}`"
                        icon="linkedin"
                        theme="dark"
                    />
                    <IconLink
                        v-if="currentCenter.youtubeRu && locale == 'ru'"
                        :destination="`${currentCenter.youtubeRu}`"
                        icon="youtube"
                        theme="dark"
                    />
                    <IconLink
                        v-else-if="currentCenter.youtube"
                        :destination="`${currentCenter.youtube}`"
                        icon="youtube"
                        theme="dark"
                    />
                    <IconLink
                        v-if="currentCenter.instagram"
                        :destination="`${currentCenter.instagram}`"
                        icon="instagram"
                        theme="dark"
                    />
                    <IconLink
                        v-if="currentCenter.telegramRu && locale == 'ru'"
                        :destination="`${currentCenter.telegramRu}`"
                        icon="telegram"
                        theme="dark"
                    />
                    <IconLink
                        v-else-if="currentCenter.telegram"
                        :destination="`${currentCenter.telegram}`"
                        icon="telegram"
                        theme="dark"
                    />
                </div>
            </div>
            <div class="navigation">
                <nav class="links-left primary-links">
                    <Anchor :url="getCenterPageUrl('research')" class="nav-link">
                        <Typography variant="h4-display" transform="capitalize">{{ $t('research') }}</Typography>
                    </Anchor>
                    <Anchor v-if="currentCenter.blog" :url="currentCenter.blog.path" class="nav-link">
                        <Typography variant="h4-display-italic" transform="capitalize"
                            >{{ $t(currentCenter.blog.title) }}
                        </Typography>
                    </Anchor>
                    <Anchor :url="currentCenter.about" class="nav-link about">
                        <Typography variant="h4-display" transform="capitalize">{{ $t('about') }}</Typography>
                    </Anchor>
                    <Anchor :url="getCenterPageUrl('experts')" class="nav-link">
                        <Typography variant="h4-display" transform="capitalize">{{ $t('experts') }}</Typography>
                    </Anchor>
                    <Anchor v-if="isGlobalCenter" url="https://donate.carnegieendowment.org/support" class="nav-link">
                        <Typography variant="h4-display">Donate</Typography>
                    </Anchor>
                </nav>
                <nav class="links-right">
                    <div class="secondary-links">
                        <Anchor
                            v-if="
                                currentCenter.title !== 'Carnegie China' &&
                                currentCenter.title !== 'Carnegie Russia Eurasia Center'
                            "
                            :url="getCenterPageUrl('programs-and-projects')"
                            class="nav-link"
                        >
                            <Typography variant="h5" transform="capitalize">{{
                                `${isGlobalCenter ? $t('programs') : $t('projects')}`
                            }}</Typography>
                        </Anchor>
                        <Anchor :url="getCenterPageUrl('events')" class="nav-link">
                            <Typography variant="h5" transform="capitalize">{{ $t('events') }}</Typography>
                        </Anchor>
                        <Anchor v-if="isGlobalCenter" :url="getCenterPageUrl('blogs')" class="nav-link">
                            <Typography variant="h5" transform="capitalize">{{ $t('blogs') }}</Typography>
                        </Anchor>
                        <Anchor v-if="isGlobalCenter" :url="getCenterPageUrl('podcasts')" class="nav-link">
                            <Typography variant="h5" transform="capitalize">{{ $t('podcasts') }}</Typography>
                        </Anchor>
                    </div>

                    <div class="tertiary-links">
                        <Anchor :url="currentCenter.contactEmail" class="nav-link">
                            <Typography variant="body" transform="capitalize">{{ $t('contact') }}</Typography>
                        </Anchor>
                        <Anchor v-if="isGlobalCenter" :url="getCenterPageUrl('annual-reports')" class="nav-link">
                            <Typography variant="body" transform="capitalize">{{ $t('annual reports') }}</Typography>
                        </Anchor>
                        <Anchor v-if="currentCenter.careers" :url="currentCenter.careers" class="nav-link">
                            <Typography variant="body" transform="capitalize">{{ $t('careers') }}</Typography>
                        </Anchor>
                        <Anchor :url="currentCenter.privacy" class="nav-link">
                            <Typography variant="body" transform="capitalize">{{ $t('privacy') }}</Typography>
                        </Anchor>
                        <Anchor v-if="currentCenter.media" :url="currentCenter.media" class="nav-link">
                            <Typography variant="body" transform="capitalize">{{ $t('for media') }}</Typography>
                        </Anchor>
                        <Anchor v-if="currentCenter.government" :url="currentCenter.government" class="nav-link">
                            <Typography variant="body" transform="capitalize">{{
                                $t(' government resources')
                            }}</Typography>
                        </Anchor>
                    </div>
                </nav>
            </div>
            <div class="subscribe">
                <SubscribeBlock
                    v-if="locale == 'ru'"
                    :title="'Берлинского центра Карнеги'"
                    newsletterFormName="BasicForm"
                />
                <SubscribeBlock v-else :title="$t(currentCenter.title)" newsletterFormName="BasicForm" />
            </div>
            <div class="copyright">
                <Typography variant="label-small">&copy; {{ $t('copyright') }}</Typography>
            </div>
        </div>
    </footer>
</template>

<script setup>
const { currentCenter, getCenterPageUrl, isGlobalCenter } = useCenters();
const { locale } = useLocale();
const { siteDirection } = useDirection();
provide('direction', siteDirection);

const CSSClasses = computed(() => [`lang-${locale.value}`]);

const props = defineProps({
    lang: {
        type: String,
        default: 'en',
    },
});
</script>

<style lang="scss" scoped>
.logo-letter-mark,
.logo-lang {
    color: color(blanc);
}

.logo-letter-mark {
    @include media-query(phone-mw) {
        display: none;
    }
}
.site-footer {
    background-color: color(blue, dark);
}

.site-footer-wrapper {
    @include content-section();
    display: grid;
    @include content-padding;

    @include media-query(phone) {
        grid-template-columns: 1fr;
        grid-template-areas:
            'subscribe'
            'navigation'
            'branding'
            'copyright';
    }

    @include media-query(tablet) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            'subscribe subscribe'
            'branding navigation'
            'copyright copyright';
    }

    @include media-query(full) {
        @include padding-tb(vertical-space(3));
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
            'branding navigation subscribe'
            'copyright copyright copyright';
    }
}

.branding {
    grid-area: branding;
    color: color(blanc, default, 0.5);
}

.logo-wrap {
    max-width: 20rem;
}

.contact {
    margin-bottom: vertical-space(1);

    @include media-query(phone) {
        margin-top: 0.8rem;
    }

    @include media-query(tablet) {
        margin-top: 2.4rem;
    }

    @include media-query(full) {
        margin-top: vertical-space(2);
    }
}

.social-media {
    display: flex;
    gap: 0.8rem;
}

.navigation {
    display: flex;
    color: color(blanc);

    @include media-query(phone) {
        padding-bottom: vertical-space(5);
        flex-direction: column;
    }
}

.links-left,
.links-right {
    flex: 1;
}

.subscribe {
    grid-area: subscribe;
    border-radius: 0.4rem;

    @include media-query(phone) {
        @include margin-tb(vertical-space(3));
    }

    @include media-query(tablet) {
        @include margin-lr(calc(-1 * var(--page-margin)));
        margin-bottom: vertical-space(3);
        border-radius: 0;
    }
}

.copyright {
    grid-area: copyright;
    color: color(blanc, default, 0.5);
    line-height: 1rem;

    @include media-query(mobile) {
        padding-bottom: 1.6rem;
    }

    @include responsive(
        padding-top,
        (
            default: vertical-space(8),
            tablet: vertical-space(7),
            phone: vertical-space(5),
        )
    );
}

.primary-links,
.secondary-links,
.tertiary-links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include media-query(phone) {
        gap: 1.2rem;
    }
}

.primary-links {
    @include media-query(phone) {
        line-height: 2.6rem;
        margin-bottom: vertical-space(2);
    }

    @include media-query(phone-mw) {
        gap: vertical-space(1);
        grid-area: primary;
    }

    .nav-link {
        color: color(blanc, default, 0.8);

        &:hover,
        &:active {
            color: color(blanc);
        }
    }
}

.secondary-links {
    margin-bottom: vertical-space(1);

    @include media-query(phone) {
        line-height: 2.2rem;
        margin-bottom: vertical-space(1.5);
    }

    @include media-query(phone-mw) {
        gap: 0.8rem;
        grid-area: secondary;
    }

    .nav-link {
        color: color(blanc, default, 0.7);

        &:hover,
        &:active {
            color: color(blanc);
        }
    }
}

.tertiary-links {
    @include media-query(phone-mw) {
        gap: 0.8rem;
        grid-area: tertiary;
    }

    .nav-link {
        color: color(blanc, default, 0.6);

        &:hover,
        &:active {
            color: color(blanc);
        }
    }
}

.contact-detail {
    display: block;
}
.lang-ru .site-footer-wrapper .about span.capitalize {
    text-transform: initial;
}
</style>
