export const titleToSlug = (string) => {
    if (!string) {
        return '';
    }

    let output = string.toLowerCase();
    output = output.replace(/[^\w ]+/g, '');
    output = output.replace(/ +/g, '-');
    return output;
};

export const getPersonFullName = (person) => {
    return `${person.firstName} ${person.middleName ? `${person.middleName} ` : ''}${person.lastName}${
        person.suffix ? ` ${person.suffix}` : ''
    }`;
};

export const getLocaleName = (locales, localeCode) =>
    locales.value.find((localeObj) => localeObj.code === localeCode)?.name;

export const toKebabCase = (string) =>
    string
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();

export const toCamelCase = (string) => {
    let ans = string.toLowerCase();
    return ans.split(' ').reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
};
