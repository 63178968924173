<template>
    <div class="modal" :class="CSSClasses">
        <div class="inner">
            <slot v-if="showHeader" name="header">
                <div class="header">
                    <div class="section">
                        <Typography class="title" variant="h3-display">{{ heading }}</Typography>
                        <Button @click="$emit('close-modal')" variant="tertiary" class="close">
                            <Icon name="clear-lg" />
                        </Button>
                    </div>
                </div>
            </slot>
            <div class="content">
                <slot name="content" />
            </div>
            <slot v-if="showFooter" name="footer">
                <div class="mobile-footer">
                    <div class="section">
                        <Button
                            @click="$emit('close-modal')"
                            variant="tertiary"
                            icon="clear"
                            iconPosition="left"
                            class="close"
                        >
                            {{ $t('close') }}</Button
                        >
                    </div>
                </div>
            </slot>
        </div>
        <div class="overlay" @click="handleOverlayClicked"></div>
    </div>
</template>

<script setup>
const props = defineProps({
    variant: {
        type: String,
        default: 'fullscreen',
        validator: (value) => {
            return ['fullscreen', 'windowed'].includes(value);
        },
    },
    heading: {
        type: String,
        default: '',
    },
    modalProp: {
        type: String,
        default: '',
    },
    showHeader: {
        type: Boolean,
        default: true,
    },
    showFooter: {
        type: Boolean,
        default: true,
    },
});

const CSSClasses = computed(() => ['subscribe-block', `variant-${props.variant}`]);
const emit = defineEmits(['close-modal']);

const handleOverlayClicked = () => {
    emit('close-modal');
};

onKeyStroke('Escape', () => {
    emit('close-modal');
});
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: scroll;
    @include z-index(modal);
    background: color(blanc);
    &.variant-windowed {
        background: transparent;
    }
}

.overlay {
    .variant-windowed & {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        @include z-index(modal, 1);
        background: color(blanc, default, 0.6);
        overflow: hidden;
    }
}
.inner {
    .variant-windowed & {
        position: relative;
        background: color(blanc);
        display: flex;
        flex-direction: column;

        width: 100%;
        height: calc(100% - (2.4rem * 2));
        margin: 2.4rem 0;
        @include z-index(modal, 2);

        @include media-query(phone-mw) {
            width: calc(100% - (3.6rem * 2));
            height: calc(100% - (3.6rem * 2));
            margin: 3.6rem;
        }
    }
}
.section {
    .variant-fullscreen & {
        @include content-section;
    }
}
.content {
    .variant-windowed & {
        overflow: auto;
        flex-grow: 1;
    }
}

.header {
    @include padding-tb(vertical-space(2));
    .variant-fullscreen & {
        border-bottom: $border;
        .section {
            justify-content: space-between;
        }
        .close {
            @include media-query(mobile) {
                display: none;
            }
        }
    }

    .variant-windowed & {
        padding: 2.4rem 2rem;
        position: absolute;
        right: 0;
        top: 0;
        .section {
            justify-content: flex-end;
        }
    }

    .section {
        display: flex;
        align-items: center;
    }
}

.title {
    text-transform: capitalize;
    .variant-windowed & {
        display: none;
    }
}

.mobile-footer {
    .variant-windowed & {
        display: none;
    }
    @include media-query(full) {
        display: none;
    }
    background: color(blanc);
    border-top: $border;
    box-shadow: 0px -4px 10px 0px color(noir, default, 0.1);
    padding: vertical-space(2);
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;

    .close {
        .icon {
            color: color(teal);

            svg {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }
}
</style>
